<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
body {
  // 防止页面关闭弹框的时候抖动
  padding-right: 0px !important;
}
</style>
