import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import "@/assets/style/reset.css";
import "@/assets/style/chang-element.scss";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import VueAMap from "vue-amap";
Vue.use(VueAMap);
import headerDom from "@/components/header/index.vue";
Vue.component("headerDom", headerDom);
VueAMap.initAMapApiLoader({
  key: "df253f7d4226356d348507725bcb58f8",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geolocation",
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4",
});
Vue.prototype.$pageSizes = [10, 20, 50, 100, 150];
import { uploadHandler } from "@/untils/uploadObsFile.js";
Vue.prototype.$uploadObsFileHandle = uploadHandler;
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
