import VueRouter from "vue-router";
import Vue from "vue";
import { getToken } from "@/untils/auth";

Vue.use(VueRouter);
// 防止路由多次点击之后报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

//基本路由
const defaultRouter = [
  {
    path: "/",
    name: "default",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/shouye"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/loginRegiste/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/loginRegiste/register.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/tranlator/agreement",
    name: "agree",
    component: () => import("@/views/translator/agreement/agreement.vue"),
    meta: {
      title: "app-服务条款",
    },
  },
  {
    path: "/tranlator/privacyPolicy",
    name: "privacy",
    component: () => import("@/views/translator/pravicy/pravicy.vue"),
    meta: {
      title: "app-隐私政策",
    },
  },
  // {
  //   path: "/translate",
  //   name: "translate",
  //   component: ()=>import('../views/MT'),
  //   meta: {
  //     title: "文本机器翻译",
  //   },
  // },
  {
    path: "/recruitment",
    name: "recruitment",
    component: () => import("../views/recruitment"),
    meta: {
      title: "文本机器翻译",
    },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/support"),
    meta: {
      title: "支持与服务",
    },
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/chanpinfuwu"),
    meta: {
      title: "产品服务",
    },
  },
  {
    path: "/shopping",
    name: "shopping",
    component: () => import("../views/shopping"),
    meta: {
      title: "云译商城",
    },
  },
  {
    path: "/shopping/detail",
    name: "shoppingDetail",
    component: () => import("../views/shopping/detail/purchaseDetail.vue"),
    meta: {
      title: "云译商城详情",
    },
  },
  {
    path: "/product/humanTranslation",
    name: "humanTranslation",
    component: () => import("../views/jumppage/humanTranslation.vue"),
    meta: {
      title: "人工翻译",
    },
  },
  {
    path: "/product/audioVisualTranslation",
    name: "audioVisualTranslation",
    component: () => import("../views/jumppage/audioVisualTranslation.vue"),
    meta: {
      title: "音视频翻译",
    },
  },
  {
    path: "/product/simultaneousInterpretion",
    name: "simultaneousInterpretion",
    component: () => import("../views/jumppage/simultaneousInterpretion.vue"),
    meta: {
      title: "同声传译",
    },
  },
  {
    path: "/solution",
    name: "solution",
    component: () => import("../views/jiejuefangan"),
    meta: {
      title: "解决方案",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/guanyu"),
    meta: {
      title: "关于云译",
    },
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreement"),
    meta: {
      title: "面对面翻译APP--用户协议",
    },
  },
  {
    path: "/agreement/site",
    name: "agreementSite",
    component: () => import("../views/agreement/guanwang.vue"),
    meta: {
      title: "云译企业官网-用户协议",
    },
  },
  {
    path: "/privacyPolicy/site",
    name: "privacyPolicySite",
    component: () => import("../views/privacyPolicy/guanwang.vue"),
    meta: {
      title: "云译企业官网--隐私政策",
    },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import("../views/privacyPolicy"),
    meta: {
      title: "面对面翻译APP--隐私政策",
    },
  },
  {
    path: "/freeExperience",
    name: "freeExperience",
    component: () => import("../views/freeExperience/free.vue"),
    meta: {
      title: "免费体验",
    },
  },

  {
    path: "/workbench",
    name: "workbench",
    component: () => import("@/views/workbench/workbench.vue"),
    meta: {
      title: "工作台",
    },
  },

  {
    path: "/recharge",
    name: "recharge",
    component: () => import("@/views/workbench/recharge.vue"),
    meta: {
      title: "充值页面",
    },
  },
  {
    path: "/pay/success",
    name: "paySuccess",
    component: () => import("@/views/workbench/paySuccess.vue"),
    meta: {
      title: "充值成功，支付提醒页面",
    },
  },
  {
    path: "/personCenter",
    name: "personCenter",
    redirect: "personalInfo",
    component: () => import("@/components/layout/layout.vue"),
    children: [
      {
        path: "/personalInfo",
        name: "personalInfo",
        meta: {
          title: "个人信息",
          defaultImg: "menu_mine_normal.png",
          selectedImg: "menu_mine_press.png",
          isMenu: true,
          isAuth: true,
        },
        component: () =>
          import("@/views/personalCenter/personal/personInfo.vue"),
      },
      {
        path: "/learning",
        name: "learning",
        meta: {
          title: "我的学习",
          defaultImg: "menu_learing_normal.png",
          selectedImg: "menu_learing_press.png",
          isMenu: true,
          isAuth: true,
        },
        component: () => import("@/views/personalCenter/learning/learning.vue"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          title: "我的订单",
          defaultImg: "menu_orders_normal.png",
          selectedImg: "menu_orders_press.png",
          isMenu: true,
          isAuth: true,
        },
        component: () => import("@/views/personalCenter/order/order.vue"),
      },
      {
        path: "/order/detail",
        name: "orderdetail",
        meta: {
          title: "订单详情",
          defaultImg: "menu_orders_normal.png",
          selectedImg: "menu_orders_press.png",
          isMenu: false,
          isAuth: true,
        },
        component: () => import("@/views/personalCenter/order/orderDetail.vue"),
      },
      {
        path: "/invoice",
        name: "invoice",
        meta: {
          title: "我的发票",
          defaultImg: "menu_bill_normal.png",
          selectedImg: "menu_bill_press.png",
          isMenu: true,
          isAuth: true,
        },
        component: () => import("@/views/personalCenter/invoice/invoice.vue"),
      },
      {
        path: "/coupon",
        name: "coupon",
        meta: {
          title: "优惠券",
          defaultImg: "menu_coupon_normal.png",
          selectedImg: "menu_coupon_press.png",
          isMenu: true,
          isAuth: true,
        },
        component: () => import("@/views/personalCenter/coupon/coupon.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes: defaultRouter,
});
router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    if (getToken()) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
