<template>
  <div class="solution">
    <div class="proService_box">
      <div
        class="columnBox"
        v-for="(item, idex) in soluList"
        :key="idex"
        :class="{
          marginRight64: idex < soluList.length,
        }"
      >
        <div class="subItem" @click="handleProduct(item)">
          <img :src="item.imgUrl" alt="" />
          <div class="nameBox">
            <span>{{ item.tit }}</span>
            <p class="tipTxt">
              {{ item.txt }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "jiejue_dialog",

  data() {
    return {
      soluList: [
        {
          tit: "实训课程",
          txt: "探索、实践、超越口语与口译的界限",
          type: 0,
          imgUrl: require("@/assets/image/chanpinfuwu/liebiao/class.png"),
          purchase: true,
        },
        {
          tit: "CATTI",
          txt: "丰富历年真题，高度仿真机考",
          type: 1,
          imgUrl: require("@/assets/image/chanpinfuwu/liebiao/catti.png"),
          purchase: true,
        },
        {
          hidden: true,
        },
        {
          hidden: true,
        },
      ],
    };
  },
  methods: {
    handleProduct(item) {
      if (item.hidden) {
        return;
      }
      localStorage.setItem("shopping", item.type || 0);
      if (this.$route.name === "shopping") {
        this.$router.go(0);
      } else {
        this.$router.push("/shopping");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.proService_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.5rem;
  width: 77.6%;

  .marginRight64 {
    margin-right: 64px;
  }
  .subItem {
    width: 320px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 12px 16px 12px 0;
    box-sizing: border-box;
    img {
      margin-right: 8px;
    }
    .nameBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #363c4b;
        line-height: 22px;
      }
      .tipTxt {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #93969d;
        line-height: 22px;
        margin-top: 8px;
      }
    }
  }
  .subItem:hover {
    background: rgba(52, 102, 239, 0.05);
    .nameBox {
      span {
        color: #3466ef;
      }
    }
  }
  .columnBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .totalTitle {
      width: 320px;
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      color: #3466ef;
      padding-bottom: 16px;
      border-bottom: 1px solid #f0f0f0;
      box-sizing: border-box;
      margin-bottom: 8px;
    }
  }
}
.solution {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 45px 5px rgba(43, 51, 62, 0.06);
  z-index: 9;
  position: fixed;
  left: 0;
  top: 64px;
  height: auto !important;
  padding-top: 32px;
  animation-name: drop-menu;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes drop-menu {
  0% {
    height: 0px;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 534px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
</style>
