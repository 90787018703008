<template>
  <div class="header">
    <div class="headerBox">
      <div class="leftSide">
        <div class="logo">
          <img :src="defaultLogo" @click="goPage('/home')" />
        </div>
        <ul class="rowStart">
          <li
            v-for="(item, index) in headerList"
            :key="index"
            :class="active == index ? 'active' : ''"
            @click="selectedTab(item)"
            @mouseenter="enterAvatar(item, index)"
            @mouseleave="leaveAvatar(item)"
          >
            <div class="headerRow">
              {{ item.txt }}
              <img src="@/assets/image/header/jiantou.png" alt="" />
            </div>
            <div class="boxList" v-show="index == 0">
              <proServiceDialog v-if="fuwuDialog"></proServiceDialog>
            </div>
            <div class="boxList" v-show="index == 1">
              <solutionDialog v-if="jiejueDialog"></solutionDialog>
            </div>
            <div class="boxList" v-show="index == 2">
              <shoppingDialog v-if="shoppingDialog"></shoppingDialog>
            </div>
            <div class="boxList" v-show="index == 3">
              <supportDialog v-if="supportDialog"></supportDialog>
            </div>
            <div class="boxList" v-show="index == 4">
              <aboutDialog v-if="guanyuyDialog"></aboutDialog>
            </div>
          </li>
        </ul>
      </div>
      <div class="rightSide">
        <template v-if="!isLogin">
          <div>
            <span class="marginRight32" @click="goPage('/login')">登录</span>
            <span class="marginRight32" @click="goPage('/register')">注册</span>
          </div>
          <div class="freeStart" @click="toFreeExp">免费体验</div>
        </template>
        <template v-else>
          <div class="flex-row-center">
            <div class="avatar">
              <img
                v-if="!userInfo.avatar"
                src="@/assets/image/workbench/common/default_avatar.png"
                alt=""
              />
              <img v-else :src="userInfo.avatar" alt="" />
            </div>
            <el-dropdown>
              <span class="el-dropdown-link nickName">
                {{ userInfo.nickName }}
                <img src="@/assets/image/header/jiantou.png" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="flex-row-center topInfo">
                    <img
                      v-if="!userInfo.avatar"
                      src="@/assets/image/workbench/common/default_avatar.png"
                      alt=""
                    />
                    <img v-else :src="userInfo.avatar" alt="" />
                    <div class="flex-col">
                      <div class="nick">{{ userInfo.nickName }}</div>
                      <span class="userAccount">{{ userInfo.username }}</span>
                    </div>
                  </div>
                  <div class="flex-row-center space">
                    <img
                      src="@/assets/image/workbench/common/coins.png"
                      alt=""
                    />
                    <div class="number">云译币：{{ userInfo.wallet }}</div>
                    <!-- <div class="txtBtn" @click="goPage('/recharge')">充值</div> -->
                  </div>
                  <div
                    class="flex-row-center hoverStyle"
                    @click="goPage('/workbench')"
                  >
                    <img
                      src="@/assets/image/workbench/common/home.png"
                      alt=""
                    />
                    <span>工作台</span>
                  </div>
                  <div
                    class="flex-row-center hoverStyle"
                    @click="goPage('/personCenter')"
                  >
                    <img
                      src="@/assets/image/workbench/common/mine.png"
                      alt=""
                    />
                    <span>个人中心</span>
                  </div>
                  <div class="flex-row-center hoverStyle" @click="logoutHandle">
                    <img
                      src="@/assets/image/workbench/common/signout.png"
                      alt=""
                    />
                    <span>退出登录</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import proServiceDialog from "@/views/chanpinfuwu/dialog/index.vue";
import solutionDialog from "@/views/jiejuefangan/dialog/index.vue";
import aboutDialog from "@/views/guanyu/dialog/index.vue";
import supportDialog from "@/views/support/dialog/index.vue";
import shoppingDialog from "@/views/shopping/dialog/shoppingDialog.vue";
import { getToken, clearToken } from "@/untils/auth";
import { logout } from "@/api/loginRegiste/loginRegister.js";

export default {
  name: "head_er",
  components: {
    proServiceDialog,
    solutionDialog,
    aboutDialog,
    supportDialog,
    shoppingDialog,
  },
  props: {
    headerActive: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    isLogin() {
      return getToken() || false;
    },
  },
  data() {
    return {
      headerList: [
        // { txt: "首页", routeName: "/home" },
        // { txt: '文本机器翻译', routeName: '/translate' },
        { txt: "产品服务", routeName: "/product" },
        { txt: "解决方案", routeName: "/solution" },
        { txt: "云译商城", routeName: "/shopping" },
        { txt: "支持与服务", routeName: "/support" },
        { txt: "关于云译", routeName: "/about" },
      ], // 头部切换
      active: -1,
      fuwuDialog: false,
      jiejueDialog: false,
      guanyuyDialog: false,
      activeHeader: true,
      supportDialog: false,
      shoppingDialog: false,
      defaultLogo: require("@/assets/image/header/logo.png"),
      shouyeLogo: require("@/assets/image/header/logo_w.png"),
    };
  },
  mounted() {
    // this.active = this.headerActive;
    // if (this.active != 0) {
    //   this.activeHeader = false;
    // }
  },
  methods: {
    logoutHandle() {
      logout().then((res) => {
        if (res.code == 200) {
          clearToken();
          this.$router.push("/login");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      this.$router.push(path);
    },
    // 头部选择切换
    selectedTab(item) {
      if (
        item.txt == "产品服务" ||
        item.txt == "解决方案" ||
        item.txt == "关于云译" ||
        item.txt == "云译商城"
      ) {
        return;
      }

      this.$router.push(item.routeName);
    },
    enterAvatar(item, index) {
      this.active = index;
      if (item.txt == "产品服务") {
        this.fuwuDialog = true;
      } else if (item.txt == "解决方案") {
        this.jiejueDialog = true;
      } else if (item.txt == "关于云译") {
        this.guanyuyDialog = true;
      } else if (item.txt == "支持与服务") {
        this.supportDialog = true;
      } else if (item.txt == "云译商城") {
        this.shoppingDialog = true;
      }
    },
    leaveAvatar(item) {
      this.active = this.headerActive;
      if (item.txt == "产品服务") {
        this.fuwuDialog = false;
      } else if (item.txt == "解决方案") {
        this.jiejueDialog = false;
      } else if (item.txt == "关于云译") {
        this.guanyuyDialog = false;
      } else if (item.txt == "支持与服务") {
        this.supportDialog = false;
      } else if (item.txt == "云译商城") {
        this.shoppingDialog = false;
      }
    },
    changeHeaderBc() {
      console.log(this.activeHeader, "this.activeHeader");
      this.activeHeader = !this.activeHeader;
    },
    // 免费体验
    toFreeExp() {
      this.$router.push("/freeExperience");
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 1.5rem;
}
.space {
  justify-content: space-between;
  padding: 16px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #e7e9ee;
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .number {
    flex: 1;
    font-size: 14px;
    color: #3b3f4b;
  }
  .txtBtn {
    cursor: pointer;
    color: #3466ef;
    margin-left: 8px;
  }
}
.topInfo {
  display: flex;
  padding-left: 30px;
  box-sizing: border-box;
  margin-top: 15px;
  img {
    width: 58px;
    height: 58px;
    margin-right: 8px;
    border-radius: 50%;
  }
  .nick {
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    color: #26282f;
  }
  .userAccount {
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    color: #6b707c;
  }
}
.hoverStyle {
  width: 263px;
  height: 32px;
  cursor: pointer;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3b3f4b;
  line-height: 32px;
  padding-left: 30px;
  background: #ffffff;
  box-sizing: border-box;
  margin-top: 10px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
/deep/ .el-dropdown-menu__item,
/deep/ .el-menu-item {
  padding: 0 !important;
  cursor: default !important;
}
/deep/ .el-dropdown-menu__item:hover,
/deep/ .el-menu-item:hover {
  background: transparent !important;
}
.hoverStyle:hover {
  background: #eff2f9 !important;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
    img {
      max-width: 36px;
      max-height: 36px;
    }
  }
  .nickName {
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    cursor: pointer;
    margin-right: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
}
.header {
  width: 100%;
  height: 64px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #efefef;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 999;
  .rowStart {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 999;
    .headerRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        margin-left: 8px;
      }
    }

    ul {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 999;
    }
    li {
      list-style-type: none;
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      color: #363c4b;
      margin-right: 32px;
      cursor: pointer;
      height: 100%;
      line-height: 64px;
    }
    .active {
      color: #3466ef;
    }
  }
  .headerBox {
    width: 100%;
    height: 100%;
    padding-left: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    color: #363c4b;
    .marginRight32 {
      margin-right: 32px;
      cursor: pointer;
    }
    .marginRight32:hover {
      color: #3466ef;
    }
    .freeStart {
      height: 66px;
      width: 144px;
      background: linear-gradient(318deg, #3466ef 0%, #1f78ff 100%);
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .leftSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .logo {
      height: 30px;
      margin-right: 80px;
      img {
        height: 30px;
        cursor: pointer;
      }
    }
  }
}
</style>
