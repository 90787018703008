export function getToken() {
  return localStorage.getItem("offical-token");
}
export function setToken(token) {
  return localStorage.setItem("offical-token", token);
}
export function getUser() {
  return localStorage.getItem("offical-user");
}
export function setUser(user) {
  return localStorage.setItem("offical-user", JSON.stringify(user));
}
export function getAccount() {
  return localStorage.getItem("offical-account");
}
export function setAccount(info) {
  return localStorage.setItem("offical-account", JSON.stringify(info));
}

export function clearToken() {
  localStorage.removeItem("offical-token");
  localStorage.removeItem("offical-user");
}
