import { v4 as uuidv4 } from "uuid";

export function getUuid() {
  let uuid = uuidv4().replaceAll("-", "");
  return uuid;
}

// 获取uuid2 不去横线
export function getUuid2() {
  return uuidv4();
}
