<template>
  <div class="proService">
    <div class="proService_box">
      <div
        class="columnBox"
        v-for="(parentItem, inx) in myList"
        :key="inx"
        :class="{
          marginRight64: inx < myList.length,
        }"
      >
        <div class="totalTitle">{{ parentItem.title }}</div>
        <div class="flex-row-cent22">
          <div
            class="subItem"
            v-for="(item, idex) in parentItem.subList"
            :key="idex"
            @click="handleProduct(item)"
          >
            <img :src="item.imgUrl" alt="" />
            <div class="nameBox">
              <span>{{ item.tit }}</span>
              <p class="tipTxt">
                {{ item.txt }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "fuwu_dialog",

  data() {
    return {
      myList: [
        {
          title: "开发集成",
          subList: [
            {
              tit: "文本翻译API",
              txt: "可接入APP、网站及智能设备",
              type: "10",
              imgUrl: require("@/assets/image/support/liebiao/api.png"),
            },
            {
              tit: "文档翻译API",
              txt: "可接入APP、网站及智能设备",
              type: "11",
              imgUrl: require("@/assets/image/support/liebiao/api.png"),
            },
            {
              tit: "语种自动识别接口",
              txt: "精准识别文本语种并返回语种结果",
              type: "12",
              imgUrl: require("@/assets/image/support/liebiao/interface.png"),
            },
          ],
        },
        {
          title: "服务",
          subList: [
            {
              tit: "音视频翻译服务",
              txt: "可根据需求提供定制化的人工服务",
              type: "14",
              imgUrl: require("@/assets/image/support/liebiao/video.png"),
            },
            {
              tit: "文本翻译服务",
              txt: "可根据需求提供定制化的人工服务",
              type: "14",
              imgUrl: require("@/assets/image/support/liebiao/text.png"),
            },
          ],
        },
        {},
        {},
      ],
    };
  },
  methods: {
    handleProduct(item) {
      // if (this.$route.name === "support") {
      //   this.$router.go(0);
      // }
      // // this.$router.go(0);
      // localStorage.setItem("support", val);
      // this.$router.push("/support");
      if (!item.purchase) {
        if (this.$route.name === "support") {
          this.$router.go(0);
        } else {
          this.$router.push("/support");
        }
        localStorage.setItem("support", item.type || 0);
      } else {
        localStorage.setItem("support", item.type || 0);
        location.reload();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-row-cent22 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.proService_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 77.6%;

  .marginRight64 {
    margin-right: 64px;
  }
  .subItem {
    width: 320px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 12px 16px 12px 0;
    box-sizing: border-box;
    img {
      margin-right: 8px;
    }
    .nameBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #363c4b;
        line-height: 22px;
      }
      .tipTxt {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #93969d;
        line-height: 22px;
        margin-top: 8px;
      }
    }
  }
  .subItem:hover {
    background: rgba(52, 102, 239, 0.05);
    .nameBox {
      span {
        color: #3466ef;
      }
    }
  }
  .columnBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    .totalTitle {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #1a1b1e;
      padding-bottom: 22px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
.proService {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 45px 5px rgba(43, 51, 62, 0.06);
  z-index: 9;
  position: fixed;
  left: 0;
  top: 64px;
  height: auto !important;
  padding-top: 32px;
  animation-name: drop-menu;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes drop-menu {
  0% {
    height: 0px;
    opacity: 0;
    visibility: hidden;
  }

  100% {
    height: 534px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
</style>
