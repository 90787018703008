import { SERVICEDEFAULTYY } from "../yYindex";
/**
 * 注册相关
 */

// 注册 -- 发送验证码
export function getRegisterCaptcha(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/register/code`,
    method: "get",
    params,
  });
}
// 手机号注册
export function registerByMobile(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/phone/register`,
    method: "post",
    data: params,
  });
}
// 邮箱注册
export function registerByEmail(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/email/register`,
    method: "post",
    data: params,
  });
}
/**
 * 登录相关
 */

// 账号登陆
export function loginByAccount(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/login/account`,
    method: "post",
    data: params,
  });
}
// 邮箱登陆
export function loginByEmail(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/login/email`,
    method: "post",
    data: params,
  });
}
// 手机号验证码登陆
export function loginByMobileCaptcha(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/login/phone`,
    method: "post",
    data: params,
  });
}

// 获取微信登陆码
export function getWeixinQrcode(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/get/mp/login/qrcode`,
    method: "get",
    params,
  });
}

// 轮询调用微信状态
export function loginWeixinQrcode(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/login/wx`,
    method: "get",
    params,
  });
}

// 个人信息-微信绑定 轮询调用微信状态
export function getQrcodeStatus(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/mp/qrcode/status`,
    method: "get",
    params,
  });
}
/**
 * 重置 密码
 */
// 手机号重置密码
export function resetByMobile(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/resetPhone`,
    method: "post",
    data: params,
  });
}
// 邮箱重置密码
export function resetByEmail(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/resetEmail`,
    method: "post",
    data: params,
  });
}

// 退出登录
export function logout(params) {
  return SERVICEDEFAULTYY({
    url: `/official-website/v1/official/logout`,
    method: "post",
    data: params,
  });
}
