<template>
  <div class="proService">
    <div class="proService_box">
      <div class="titleTip">产品服务</div>
      <div class="contentBody">
        <div class="totalTitle">
          <p
            v-for="(parentItem, inx) in myList"
            :key="inx"
            :class="{
              act: parentItem.title == clickTabName,
            }"
            @click="clickTabName = parentItem.title"
          >
            {{ parentItem.title }}
          </p>
        </div>
        <div class="flex-row">
          <div
            class="subItem"
            v-for="(item, idex) in subList"
            :key="idex"
            @click="handleProduct(item)"
          >
            <img :src="item.imgUrl" alt="" />
            <div class="nameBox">
              <span>{{ item.tit }}</span>
              <p class="tipTxt">
                {{ item.txt }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "fuwu_dialog",

  data() {
    return {
      myList: [
        {
          title: "智慧办公",
          subList: [
            {
              tit: "译境：文本文档翻译",
              txt: "双语对照，即时翻译，译后编辑",
              type: "0",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/yijing.png"),
            },
            {
              tit: "TransWAI：音视频翻译",
              txt: "音视频转写、翻译、语音合成的字幕生成全流程",
              type: "1",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/transwai.png"),
            },
            {
              tit: "多人协同翻译",
              txt: "团队协同，项目全流程管理",
              type: "5",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/duoren.png"),
            },
            {
              tit: "AI同声传译",
              txt: "智能化的语音实时翻译",
              type: "2",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/tongchuan.png"),
            },
          ],
        },
        {
          title: "智慧教育",
          subList: [
            {
              tit: "笔译教学",
              txt: "简单、智能、基于真实项目的教学实训系统",
              type: "17",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/biyi.png"),
            },
            {
              tit: "口译教学",
              txt: "机器实时评分，辅助教学",
              type: "18",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/kouyi.png"),
            },
            {
              tit: "口语口译实训",
              txt: "探索、实践、超越口语与口译的界限",
              type: 0,
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/class.png"),
              purchase: true,
            },
            {
              tit: "CATTI考试",
              txt: "丰富历年真题，高度仿真机考",
              type: 1,
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/catti.png"),
              purchase: true,
            },
          ],
        },
        {
          title: "智慧科研",
          subList: [
            {
              tit: "语料管理",
              txt: "词性标注、术语提出、语料对齐",
              type: "7",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/corpus.png"),
            },
            {
              tit: "国别区域研究",
              txt: "接轨国际实时资讯，机器翻译+人工审校双重保障",
              type: "4",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/yanjiu.png"),
            },
            {
              tit: "知识图谱",
              txt: "智能搜索、智能推荐、分析决策",
              type: "8",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/knowledge.png"),
            },
          ],
        },
        {
          title: "智慧移动",
          subList: [
            {
              tit: "离线手持终端",
              txt: "优质小巧、适用于各种终端硬件",
              type: "6",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/zhongduan.png"),
            },
            {
              tit: "翻译聊天工具",
              txt: "打破跨语言沟通障碍",
              type: "19",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/chat.png"),
            },
            {
              tit: "翻译插件",
              txt: "windows插件、office插件、trados插件",
              type: "9",
              imgUrl: require("@/assets/image/chanpinfuwu/liebiao/plug-in.png"),
            },
          ],
        },
      ],
      clickTabName: "智慧办公",
    };
  },
  computed: {
    subList() {
      let obj = this.myList.filter((el) => {
        return el.title == this.clickTabName;
      })[0];
      return obj.subList;
    },
  },
  methods: {
    handleProduct(item) {
      if (!item.purchase) {
        if (this.$route.name === "product") {
          this.$router.go(0);
        } else {
          this.$router.push("/product");
        }
        window.scrollTo(0, 0);
        localStorage.setItem("product", item.type || 0);
      } else {
        localStorage.setItem("shopping", item.type || 0);
        if (this.$route.name == "shopping") {
          location.reload();
        } else {
          this.$router.push({
            path: "/shopping",
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.titleTip {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #1a1b1e;
}
.flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -12px;
}
.proService_box {
  width: 77.6%;
  .contentBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .totalTitle {
      width: 84px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      p {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #363c4b;
        line-height: 22px;
        margin-bottom: 16px;
      }
      p.act {
        position: relative;
        color: #3466ef;
      }
      p.act::after {
        content: "";
        width: 57px;
        height: 2px;
        background: #3466ef;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  .subItem {
    width: 320px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 12px 16px 12px 0;
    box-sizing: border-box;
    margin-left: 84px;
    img {
      margin-right: 8px;
    }
    .nameBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #363c4b;
        line-height: 22px;
      }
      .tipTxt {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #93969d;
        line-height: 22px;
        margin-top: 8px;
      }
    }
  }
  .subItem:hover {
    background: rgba(52, 102, 239, 0.05);
    .nameBox {
      span {
        color: #3466ef;
      }
    }
  }
}
.proService {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 45px 5px rgba(43, 51, 62, 0.06);
  z-index: 9;
  position: fixed;
  left: 0;
  top: 64px;
  padding-top: 24px;
  padding-bottom: 20px;
  height: auto !important;
  animation-name: drop-menu;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@keyframes drop-menu {
  0% {
    height: 0px;
    opacity: 0;
    visibility: hidden;
  }

  100% {
    height: 534px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
</style>
