import { getUuid } from "@/untils/uuid";

let obsConfig = {
  Bucket: "cloudtranslation-ow",
  access_key_id: "2CNBVV3R4B1H2JZ6AHAT",
  secret_access_key: "g9nMiK6vFqTwxGQfUkUyE9J5DO2gaqhJT8vh4HqX",
  server: "https://obs.cn-south-1.myhuaweicloud.com",
  filePath: "https://cloudtranslation-ow.obs.cn-south-1.myhuaweicloud.com/",
};
//初始化obs
let obsClient = null;
function obsInit() {
  // eslint-disable-next-line no-undef
  obsClient = new ObsClient({
    access_key_id: obsConfig.access_key_id,
    secret_access_key: obsConfig.secret_access_key,
    server: obsConfig.server,
  });
}
/**
 * 上传文件
 * @param {*} file 文件
 * @param {*} fileFolder 文件夹
 */
export function uploadHandler(
  file,
  fileFolder = "common",
  isAudio = false,
  fileIdIsUUid,
  duration = null
) {
  obsInit();
  var index = file.name.lastIndexOf(".");
  var fileType = file.name.substring(index + 1, file.name.length);
  var uuid = getUuid();
  if (isAudio) {
    let url = URL.createObjectURL(file); //获取录音时长
    let audioElement = new Audio(url); //audio也可获取视频的时长
    audioElement.addEventListener("loadedmetadata", function () {
      duration = audioElement.duration;
    });
  }
  let filePah = obsConfig.filePath + fileFolder + "/" + uuid + "/" + file.name;

  let fileObj = {
    uid: file.uid,
    fileId: fileIdIsUUid ? uuid : file.uid,
    id: fileIdIsUUid ? uuid : file.uid,
    fileDuration: duration,
    fileName: file.name.substr(0, 30),
    fileSize: parseInt(Number(file.size) / 1024 / 1024),
    fileExtension: fileType,
    obsPath: filePah,
    filePath: filePah,
  };
  return new Promise((resove, reject) => {
    obsClient.putObject(
      {
        Bucket: obsConfig.Bucket,
        Key: fileFolder + "/" + uuid + "/" + file.name,
        SourceFile: file,
        // TaskNum: 15,
        // PartSize: 5 * 1024 * 1024,
        // enableCheckpoint: true,
      },
      (err, result) => {
        if (err) {
          //失败
          this.$message.warning("上传失败:" + err);
          reject({
            code: 500,
            msg: "上传失败！",
          });
        } else {
          //成功
          if (result.CommonMsg.Status == "200") {
            resove({
              code: 200,
              fileObj: fileObj,
            });
          } else {
            //失败
            reject({
              code: 500,
              msg: "上传失败！",
            });
          }
        }
      }
    );
  });
}
