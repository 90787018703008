import { setUser, setToken, getUser } from "@/untils/auth";
const state = {
  userInfo: JSON.parse(getUser()) || {}, // JSON.parse(getUser())
  avatar: localStorage.getItem("avatarUrl"),
};
const mutations = {
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken(token);
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
    setUser(userInfo);
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
