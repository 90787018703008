import axios from "axios";
import { Message, Loading } from "element-ui";
import { getToken } from "@/untils/auth.js";
const SERVICEDEFAULTYY = axios.create({
  timeout: 60 * 60 * 1000,
});
let loading;
function showLoading() {
  loading = Loading.service({
    lock: true,
    text: "",
    background: "rgba(0, 0, 0, 0.2)",
  });
}
SERVICEDEFAULTYY.interceptors.request.use(
  (config) => {
    showLoading();
    let token = getToken();
    if (token != null) {
      config.headers.Authorization = `Bearer ` + token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
SERVICEDEFAULTYY.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      Message.error(response.data.msg);
    }
    console.log("response", response);
    loading.close();
    return response.data;
  },
  (error) => {
    console.log("error", error);
    if (error && error.response) {
      Message.error(error.response.data.msg || error.response.data.error);
      if (error.response.status === 401) {
        this.$router.push("/login");
      }
    }
    loading.close();
    return Promise.reject(error);
  }
);
SERVICEDEFAULTYY.all = axios.all; // all方法没有挂载到 axios 实例对象中

export { SERVICEDEFAULTYY };
